import Contact from './Contact';
import Coordination from './Coordination';
import Footer from './Footer';
import Navigation from './Navigation';
import Projects from './Projects';

const Home = () => {
  return (
    <>
      <Navigation />
      <Coordination />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
