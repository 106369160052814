import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='flex justify-between p-10 pl-16 pr-16'>
      <p className='font-medium text-gray-500'>&copy; Wolfgang Weissenböck</p>

      <Link
        to='/impressum'
        className='font-medium text-gray-500 hover:text-gray-900'
      >
        Impressum
      </Link>
    </div>
  );
};

export default Footer;
