import header from './assets/header.jpg';
import { useState } from 'react';

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = (shouldShowMenu) => {
    setShowMenu(shouldShowMenu);
  };

  return (
    <div className='relative bg-white overflow-hidden'>
      <div className='max-w-7xl mx-auto'>
        <div className='relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32'>
          <svg
            className='hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2'
            fill='currentColor'
            viewBox='0 0 100 100'
            preserveAspectRatio='none'
            aria-hidden='true'
          >
            <polygon points='50,0 100,0 50,100 0,100' />
          </svg>

          <div>
            <div className='relative pt-6 px-4 sm:px-6 lg:px-8'>
              <nav
                className='relative flex items-center justify-between sm:h-10 lg:justify-start'
                aria-label='Global'
              >
                <div className='flex items-center flex-grow flex-shrink-0 lg:flex-grow-0'>
                  <div className='flex items-center justify-between w-full md:w-auto'>
                    <a href='/'>
                      <h1 className='text-xl tracking-tight font-bold text-gray-900 sm:text-xl sm:tracking-tight md:text-xl md:tracking-tight'>
                        <span className='block md:inline'>Wolfgang</span>
                        <span className='block text-blue-500 md:inline'>
                          Weissenböck
                        </span>
                      </h1>
                    </a>
                    <div className='-mr-2 flex items-center md:hidden'>
                      <button
                        type='button'
                        className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                        aria-expanded='false'
                        onClick={() => toggleMenu(true)}
                      >
                        <span className='sr-only'>Open main menu</span>
                        <svg
                          className='h-6 w-6'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='2'
                          stroke='currentColor'
                          aria-hidden='true'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M4 6h16M4 12h16M4 18h16'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='hidden md:block md:ml-10 md:pr-4 md:space-x-8'>
                  <a
                    href='#baukoordination'
                    className='font-medium text-gray-500 hover:text-gray-900'
                  >
                    Baukoordination
                  </a>
                  <a
                    href='#projekte'
                    className='font-medium text-gray-500 hover:text-gray-900'
                  >
                    Projekte
                  </a>
                  <a
                    href='#kontakt'
                    className='font-medium text-gray-500 hover:text-gray-900'
                  >
                    Kontakt
                  </a>
                </div>
              </nav>
            </div>

            {showMenu && (
              <div className='absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'>
                <div className='rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden'>
                  <div className='px-5 pt-4 flex items-center justify-between'>
                    <div>
                      <h1 className='text-xl tracking-tight font-bold text-gray-900 sm:text-xl sm:tracking-tight md:text-xl md:tracking-tight'>
                        <span className='block md:inline'>Wolfgang</span>
                        <span className='block text-blue-500 md:inline'>
                          Weissenböck
                        </span>
                      </h1>
                    </div>
                    <div className='-mr-2'>
                      <button
                        type='button'
                        className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                        onClick={() => toggleMenu(false)}
                      >
                        <span className='sr-only'>Close main menu</span>
                        <svg
                          className='h-6 w-6'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='2'
                          stroke='currentColor'
                          aria-hidden='true'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M6 18L18 6M6 6l12 12'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className='px-2 pt-2 pb-3 space-y-1'>
                    <a
                      href='#baukoordination'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
                    >
                      Baukoordination
                    </a>
                    <a
                      href='#projekte'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
                    >
                      Projekte
                    </a>
                    <a
                      href='#kontakt'
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50'
                    >
                      Kontakt
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          <main className='mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28'>
            <div className='sm:text-center lg:text-left'>
              <h1 className='text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight'>
                <span className='block xl:inline'>Für mehr </span>
                <span className='block text-blue-500 xl:inline'>
                  Sicherheit
                </span>
                <span className='block xl:inline'> am Bau</span>
              </h1>
              <p className='mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                Ihr Bauvorhaben benötigt eine Baukoordination? Dann lassen Sie
                uns Ihr Projekt gemeinsam umsetzen.
              </p>
              <div className='mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start'>
                <div className='rounded-md shadow'>
                  <a
                    href='#kontakt'
                    className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 md:py-4 md:text-lg md:px-10'
                  >
                    Jetzt kontaktieren
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
        <img src={header} alt='Banner'></img>
      </div>
    </div>
  );
};

export default Navigation;
