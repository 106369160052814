const Coordination = () => {
  return (
    <div className='py-12 bg-white' id='baukoordination'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='lg:text-center'>
          <h2 className='text-lg text-blue-500 font-semibold'>
            Baukoordination
          </h2>
          <p className='mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight'>
            Planungs- und Baukoordination laut BauKG
          </p>
          <p className='mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto'>
            Jahrelange Tätigkeit in der Betreuung von Hoch- und Tiefbauten
            machen mich zu einem erfahrenen Partner bei der sicheren Umsetzung
            Ihres Projektes.
          </p>
        </div>

        <div className='mt-10'>
          <dl className='space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10'>
            <div className='relative'>
              <dt>
                <div className='absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white'>
                  {/* heroicons: building-office-2 */}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z'
                    />
                  </svg>
                </div>
                <p className='ml-16 text-lg leading-6 font-medium text-gray-900'>
                  Baustellenkoordination
                </p>
              </dt>
              <dd className='mt-2 ml-16 text-base text-gray-500'>
                <div className='mt-4'>
                  <ul className='pl-4 list-disc text-base space-y-2'>
                    <li className='text-gray-500'>
                      <span className='text-gray-600'>
                        Überwachen der Einhaltung der SiGe-Maßnahmen
                      </span>
                    </li>

                    <li className='text-gray-500'>
                      <span className='text-gray-600'>
                        Erstellen und Versand der BauKG-Dokumentation
                      </span>
                    </li>

                    <li className='text-gray-500'>
                      <span className='text-gray-600'>
                        Adaption des SiGe-Plans bei geändertem Bauablauf
                      </span>
                    </li>

                    <li className='text-gray-500'>
                      <span className='text-gray-600'>
                        Anpassung und Fertigstellung der Unterlage für spätere
                        Arbeiten
                      </span>
                    </li>
                  </ul>
                </div>
              </dd>
            </div>

            <div className='relative'>
              <dt>
                <div className='absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white'>
                  {/* heroicons: book-open */}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25'
                    />
                  </svg>
                </div>
                <p className='ml-16 text-lg leading-6 font-medium text-gray-900'>
                  Planungskoordination
                </p>
              </dt>
              <dd className='mt-2 ml-16 text-base text-gray-500'>
                <div className='mt-4'>
                  <ul className='pl-4 list-disc text-base space-y-2'>
                    <li className='text-gray-500'>
                      <span className='text-gray-600'>
                        Erstellung des SiGe-Plans (Sicherheits- und
                        Gesundheitsschutzdokument)
                      </span>
                    </li>

                    <li className='text-gray-500'>
                      <span className='text-gray-600'>Vorankündigung</span>
                    </li>

                    <li className='text-gray-500'>
                      <span className='text-gray-600'>Baustellenordnung</span>
                    </li>

                    <li className='text-gray-500'>
                      <span className='text-gray-600'>
                        Unterlage für spätere Arbeiten
                      </span>
                    </li>
                  </ul>
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Coordination;
