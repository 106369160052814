import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';

const Legal = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <main class='mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28'>
      <div class='sm:text-center lg:text-left'>
        <div class="mb-10">
          <Link to='/'>
            <a
              href='/'
              class='flex w-64 gap-6 items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 md:py-4 md:text-lg md:px-10'
            >
              {/* heroicons: arrow-left */}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                class='w-6 h-6'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18'
                />
              </svg>

              <p>zur Startseite</p>
            </a>
          </Link>
        </div>

        <h1 class='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
          <span class='block text-gray-900 xl:inline' id='colored-headline'>
            Impressum
          </span>
        </h1>
        <p class='mt-3 text-base text-gray-500'>
          Informationspflicht laut §5 E-Commerce Gesetz, §14
          Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
          laut §25 Mediengesetz.
        </p>
        <p class='mt-8 text-base text-gray-900 font-bold'>Kontaktdaten des Inhabers</p>
        <p class='mt-2 text-base text-gray-500'>
          Wolfgang Weissenböck <br></br>
          Berndorf 19 <br></br>
          4723 Natternbach <br></br>
          Telefonnummer:  0664 5449871 <br></br>
          E-Mail: office@weissenboeck-baukg.at <br></br>
        </p>

        <p class='mt-8 text-base text-gray-900 font-bold'>Inhalt des Onlineangebotes</p>
        <p class='mt-2 text-base text-gray-500'>
          Der Autor übernimmt keinerlei Gewähr für die Aktualität, Richtigkeit und Vollständigkeit der bereitgestellten Informationen auf unserer Website. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>

        <p class='mt-8 text-base text-gray-900 font-bold'>Verweise und Links</p>
        <p class='mt-2 text-base text-gray-500'>
          Trotz sorgfältiger inhaltlicher Kontrolle wird keine Haftung für die Inhalte externer Links übernommen. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>

        <p class='mt-8 text-base text-gray-900 font-bold'>Urheber- und Kennzeichenrecht</p>
        <p class='mt-2 text-base text-gray-500'>
          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.
        </p>

        <p class='mt-8 text-base text-gray-900 font-bold'>Datenschutz</p>
        <p class='mt-2 mb-8 text-base text-gray-500'>
          Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist – soweit technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten.
        </p>
      </div>
    </main>
  );
};

export default Legal;
